import { http_client, http_client_with_status } from "@/plugins/http_client";

// petición GET
const getListFondos = async (filters) => await http_client('/api/v1/baja/cuantia', filters);
const getListRegistrosFondo = async (id_fondo, filters) => await http_client_with_status(`/api/v1/baja/cuantia/revision-registros-fondo-baja-cuantia/${id_fondo}`, filters, 'get');
const getListRegsFondos = async (id_fondo, filters) => await http_client(`/api/v1/baja/cuantia/${id_fondo}/registros`, filters);
const getFondoById = async (id_fondo, filter) => await http_client(`/api/v1/baja/cuantia/${id_fondo}`, filter)
const getEmpleadoByEmail = async (filter) => await http_client(`/api/v1/baja/cuantia/administradores`, filter)
const getListRegsFondosAuditoria = async (id_fondo, filters) => await http_client(`/api/v1/compra/proceso/auditoria/${id_fondo}/bajaCuantia/registros`, filters);

// petición POST
const postAddFondo = async (body = {}) => await http_client('/api/v1/baja/cuantia', body, 'post');
const postAddAdminFondo = async (id_fondo, id_admin, body = {}) => await http_client(`/api/v1/baja/cuantia/${id_fondo}/administradores/${id_admin}`, body, 'post');
const postAddRegFondo = async (id_fondo, body = {}) => await http_client(`/api/v1/baja/cuantia/${id_fondo}/registros`, body, 'post');

// petición PUT
const putEditFondo = async (id_fondo, body = {}) => await http_client(`/api/v1/baja/cuantia/${id_fondo}`, body, 'put');

// petición DELETE
const deleteAdminFondo = async (id_admin, id_fondo) => await http_client(`/api/v1/baja/cuantia/administradores/${id_admin}`, {}, 'delete');

const marcarRegistroComoRevisado = (id_gasto) => http_client_with_status(`/api/v1/baja/cuantia/marcar-como-revisado/${id_gasto}`, null, 'patch');

const marcarRegistroComoObservado = (id_fondo, data) => http_client_with_status(`/api/v1/baja/cuantia/marcar-como-observado/${id_fondo}`, data, 'patch');

const observadaFondo = (id_gasto) => http_client_with_status(`/api/v1/baja/cuantia/observada/${id_gasto}`, null, 'patch');


const enviarNotificacionUCP = async (params = {}) => await http_client(`/api/v1/baja/cuantia/notificar`, params, "post");

const getReporteGeneralFondos = async () =>
  await http_client(
    "/api/v1/baja/cuantia/reporte/general",
    {},
    "get",
    { responseType: "blob" },
    true,
    "blob",
    false
  );

const getReporteFondo = async (id_fondo_baja_cuantia) =>
  await http_client(
    `/api/v1/baja/cuantia/${id_fondo_baja_cuantia}/reporte`,
    {},
    "get",
    { responseType: "blob" },
    true,
    "blob",
    false
  );

const deleteGastoFondo = async (id_gasto) => await http_client(`/api/v1/baja/cuantia/registros/${id_gasto}`, {}, 'delete');

const editarGastoFondo = async (idRegistro, data) => await http_client(`/api/v1/baja/cuantia/editar/registro/${idRegistro}`, data, 'put');

const uploadExcel = async (id_fondo, body = {}) => await http_client(`/api/v1/baja/cuantia/reporte/${id_fondo}`, body, 'post');

const postAsueto = async (body = {}) => await http_client('/api/v1/asuetos/fecha-fin', body, 'post');

const cargarInstituciones = (filtros) => http_client_with_status('/api/v1/baja/cuantia/cargar/instituciones', filtros, 'get');

const cargarInstitucionesOld = (filtros) => http_client('/api/v1/baja/cuantia/cargar/instituciones/old', filtros, 'get');

const listarFondos = (filtros) => http_client_with_status('/api/v1/baja/cuantia/list/fondos', filtros, 'get');

const cargarConteoRegistros = (idFondo) => http_client_with_status(`/api/v1/baja/cuantia/conteo/${idFondo}`, null, 'get');

const aprobarcionMasivaRegistros = async (id_fondo, body = {}) => await http_client(`/api/v1/baja/cuantia/registros-revisados/${id_fondo}`, body, 'post');

const cargarCatalogoEstados = async (params) => http_client_with_status('/api/v1/baja/cuantia/catalogo/estado-registro', params, 'get');

export default {
  getListFondos,
  getListRegsFondos,
  getFondoById,
  getEmpleadoByEmail,
  getListRegsFondosAuditoria,
  postAddFondo,
  postAddAdminFondo,
  postAddRegFondo,
  putEditFondo,
  deleteAdminFondo,
  enviarNotificacionUCP,
  getReporteGeneralFondos,
  getReporteFondo,
  deleteGastoFondo,
  uploadExcel,
  postAsueto,
  getListRegistrosFondo,
  marcarRegistroComoRevisado,
  observadaFondo,
  cargarInstituciones,
  listarFondos,
  marcarRegistroComoObservado,
  editarGastoFondo,
  cargarConteoRegistros,
  aprobarcionMasivaRegistros,
  cargarCatalogoEstados,
  cargarInstitucionesOld
}