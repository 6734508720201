export default () => ({
  idOrdenCompra: null,
  etapaEditable: false,
  etapasList: [],
  filters: {
    page: 1,
    per_page: 10,
    total_rows: null,
    fecha_inicio: null,
    fecha_fin: null,
    estado: null,
  },
  etapaContrato: {
    id: null,
    etapa: null,
    descripcion: null,
    fecha_inicio: null,
    fecha_fin: null,
    obs: [],
  },
  fecha_inicio_anterior: null, //Fecha de inicio para recepción de ofertas
  etapaProgreso: 0,
  id_proceso_compra: null,
  lista_tecnico: [],
  fecha_hora_fin:null,
  lista_tipos_documentos: [],
  info_general: {
    id: null,
    id_forma_contratacion: null, // 2: Compra directa, 4: Libre gestión
    id_seguimiento_proceso: null, // 1:No publicado 2: Publicado
    nombre_proceso: null,
    tecnico: null,
    numero_proceso: null,
    justificacion_compra: null,
    forma_contratacion: null,
    codigo_proceso: null,
    id_etapa:null
  },
  lista_administradores: [],
  lista_documentos: [],
  lista_fases: [],
  correlativo_proceso: null,
  lista_proveedores: [],
  nombre_comercial: null, // Modelo para el autocomplete
  numero_documento: null, // Modelo para el autocomplete en ofertas
  proveedor_invitar: null, // Proveedor seleccionado para invitar
  publicar_loading: false,
  contratoExistente: {},
  lista_documentos_paac: {
    //Para paginar documentos en listado paac
    page: 1,
    per_page: 10,
    total_rows: 0,
  },
  lista_ofertas: {
    //Para paginar ofertas
    page: 1,
    per_page: 10,
    total_rows: 0,
  },
});
