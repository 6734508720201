import { http_client_slim } from "@/plugins/http_client";

const client = http_client_slim("/api/v1/procesos/admin");

const listarProcesos = async (data = {}) =>
  client(`/listar/procesos`, { data });


export default {
  listarProcesos
};
