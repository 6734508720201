export const setInfoGeneral = (state, payload) => {
  state.info_general.id = payload.id;
  state.info_general.id_forma_contratacion = payload.id_forma_contratacion;
  state.info_general.id_seguimiento_proceso = payload.id_seguimiento_proceso;
  state.info_general.nombre_proceso = payload.nombre_proceso;
  state.info_general.codigo_proceso = payload.codigo_proceso;
  state.lista_administradores = payload.administradores_contrato;
  state.info_general.id_etapa = payload.etapa?.id_etapa;
};

export const setListaTecnico = (state, payload) => {
  state.lista_tecnico = payload;
};

export const setListaTipoDocumentos = (state, payload) => {
  state.lista_tipos_documentos = payload;
};

export const setListaDocumentos = (state, payload) => {
  state.lista_documentos = payload;
};

export const setListaOfertas = (state, payload) => {
  state.lista_ofertas = payload;
};

export const setListaFases = (state, payload) => {
  state.lista_fases = payload;
};

export const setIdOrdenCompra = (state, val) => {
  state.idOrdenCompra = val;
};

export const setEtapaEditable = (state, val) => {
  state.etapaEditable = val;
};

export const obtenerEtapas = (state, val) => {
  state.etapasList = val;
};

export const limpiarEtapas = (state) => {
  state.idOrdenCompra = null;
  state.etapasList = [];
  state.filters.page = 1;
  state.filters.per_page = 10;
  state.filters.total_rows = 0;
  state.filters= {
    page: 1,
    per_page: 10,
    total_rows: null,
    fecha_inicio: null,
    fecha_fin: null,
    estado: null,
  };
};

export const setEtapa = (state, val) => {
  Object.keys(state.etapaContrato).forEach((key) => {
    state.etapaContrato[key] = val[key];
  });
};

export const clearEtapa = (state) => {
  Object.keys(state.etapaContrato).forEach((key) => {
    state.etapaContrato[key] = null;
    if (key == "obs") {
      state.etapaContrato[key] = [];
    }
  });
};

export const getEtapaProgreso = (state, val) => {
  state.etapaProgreso = val;
};

export const setFiltroFechaInicio = (state, val) => {
  state.filters.fecha_inicio = val;
};

export const setFiltroFechaFin = (state, val) => {
  state.filters.fecha_fin = val;
};

export const setFiltroEstado = (state, val) => {
  state.filters.estado = val;
};

export const setPage = (state, val) => {
  state.filters.page = val;
};

export const setPerPage = (state, val) => {
  state.filters.per_page = val;
};

export const setPaginationData = (state, payload) => {
  state.filters.page = Number(payload.page);
  state.filters.per_page = Number(payload.per_page);
  state.filters.total_rows = Number(payload.total_rows);
};

export const setEntregaLista = (state, payload) => {
  state.entregaLista = payload;
};

export const setCorrelativoProceso = (state, payload) => {
  state.correlativo_proceso = payload;
};

export const setListaProveedores = (state, payload) => {
  state.lista_proveedores = payload;
};

export const setNombreComercial = (state, payload) => {
  state.nombre_comercial = payload;
};

export const setNumeroDocumento = (state, payload) => {
  state.numero_documento = payload;
};

export const setProveedor = (state, payload) => {
  state.proveedor_invitar = payload;
};

export const setPublicarLoading = (state, payload) => {
  state.publicar_loading = payload;
};

export const setContratoExistente = (state, payload) => {
  state.contratoExistente = payload;
};

export const setFechaInicioAnterior = (state, payload) => {
  state.fecha_inicio_anterior = payload;
};

export const setFechaFin = (state, payload) => {
  state.fecha_hora_fin = payload
};

export const setPaginationDocumentos = (state, payload) => {
  //Mutacion para realizar la paginacion de Documentos
  state.lista_documentos_paac.page = Number(payload.page);
  state.lista_documentos_paac.per_page = Number(payload.per_page);
  state.lista_documentos_paac.total_rows = Number(payload.total_rows);
};

export const setPaginationOfertas = (state, payload) => {
  //Mutacion para realizar la paginacion de Ofertas
  state.lista_ofertas.page = Number(payload.page);
  state.lista_ofertas.per_page = Number(payload.per_page);
  state.lista_ofertas.total_rows = Number(payload.total_rows);
};
