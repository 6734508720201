import { http_client_procesos_publicos } from "@/plugins/http_client_procesos_publicos";

const obtenerProcesoPublicos = async(filters) => await http_client_procesos_publicos('/api/v1/publico/obtener/procesos/publicos', filters, 'get');

const obtenerBeneficiariosProcesoPublicos = async(filters) => await http_client_procesos_publicos('/api/v1/publico/obtener/beneficiarios/procesos', filters, 'get');

const cargarInstituciones = (filtros) => http_client_procesos_publicos('/api/v1/publico/obtener/instituciones', filtros, 'get');

const obtenerModalidades = (filtros) => http_client_procesos_publicos('/api/v1/publico/obtener/modalidades', filtros, 'get');

const obtenerEstados = (filtros) => http_client_procesos_publicos('/api/v1/publico/obtener/estados', filtros, 'get');

const obtenerDetalleProceso = (id, filtros) => http_client_procesos_publicos(`/api/v1/publico/obtener/detalle/procesos/publicos/${id}`, filtros, 'get');

const obtenerDocumentos = (id_proceso_compra, filtros) => http_client_procesos_publicos(`/api/v1/publico/${id_proceso_compra}/documentos`, filtros, 'get');

const actualizarEstadoPublico = (id, params) => http_client_procesos_publicos(`/api/v1/publico/actualizar/estado/${id}`, params, 'put');

const actualizarEstadoDocumentoPublico = (id, params) => http_client_procesos_publicos(`/api/v1/publico/actualizar/estado/documentos/${id}`, params, 'put');

const obtenerDocumentosPublicos = (id_proceso, params) => http_client_procesos_publicos(`/api/v1/publico/obtener/documentos/procesos/publicos/${id_proceso}`, params, 'get');


export default{
    obtenerProcesoPublicos,
    cargarInstituciones,
    obtenerModalidades,
    obtenerEstados,
    obtenerDetalleProceso,
    obtenerDocumentos,
    actualizarEstadoPublico,
    actualizarEstadoDocumentoPublico,
    obtenerDocumentosPublicos,
    obtenerBeneficiariosProcesoPublicos
}
